<template>
  <div class="p-4 bg-white rounded-lg my-4" v-if="isDataLoaded">
    <div class="overflow scrollable">
      <div class="row pa-4">
        <div
          v-for="data in serverData.info.colors"
          :key="data.name"
          class="d-flex justify-start align-items-center py-2 col-6 col-sm-4 col-md-2"
        >
          <div
            class="pa-4 rounded"
            :style="`background-color:${data.color};`"
          ></div>
          <div class="pa-1"></div>
          <div class="font-weight-bold text--disabled">{{ data.name }}</div>
        </div>
      </div>
      <div>
        <div class="pa-4" v-for="(grid, n) in serverData.grids" :key="n">
          <div class="h4">
            {{ grid.title }}
          </div>
          <div class="overflow scrollable">
            <table>
              <tbody>
                <tr v-for="(row, i) in rotatedArray(grid.grid)" :key="i">
                  <td v-for="(col, j) in row" :key="j">
                    <v-menu v-if="col.type == 'chute'" open-on-hover offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <div v-bind="attrs" v-on="on"> -->
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="pa-4"
                          :style="`background-color: ${col.color}; border: 1px solid grey;`"
                        ></div>
                        <!-- </div> -->
                      </template>
                      <v-card>
                        <div class="pa-4">
                          <div class="px-2 py-2 font-weight-bold text-h6">
                            {{ col.name }}
                          </div>
                          <v-divider />
                          <v-list dense>
                            <v-list-item
                              v-for="loc in col.extra.locations"
                              :key="loc"
                            >
                              <v-list-item-icon>
                                <v-icon color="grey">
                                  mdi-map-marker-circle
                                </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="loc"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <!-- <div class="d-flex flex-column">
                            <div
                              v-for="(loc, m) in col.extra.locations"
                              :key="m"
                            >
                              <span class="pa-1 py-2">{{ loc }}</span>
                            </div>
                          </div> -->
                        </div>
                      </v-card>
                    </v-menu>
                    <!-- style="width: 23px; height: 23px" -->
                    <div
                      v-else
                      class="pa-4"
                      :style="`background-color: ${col.color}; border: 1px solid grey;`"
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "WesChuteGrid",

  data: () => ({
    serverData: null,
  }),
  methods: {
    async initTableContent() {
      await ApiService.post("/wes/wesChuteGrid")
        .then((res) => {
          this.serverData = res.data;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    rotatedArray(dataArray) {
      const col = dataArray.length;
      const row = dataArray[0].length;
      const rotated = new Array(row).fill(0);
      for (let i = 0; i < row; i++) {
        rotated[i] = new Array(col).fill(0);
      }
      for (let i = 0; i < col; i++) {
        for (let j = 0; j < row; j++) {
          rotated[j][col - 1 - i] = dataArray[i][j];
        }
      }
      return rotated;
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
  beforeDestroy() {
    this.serverData = null;
  },
};
</script>
