<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      ref="datatable"
      :table_state="table_state"
      :store_names="store_names"
      :skipAddButton="true"
      :delete_url="delete_url"
      :overrideActionsWith="actions"
      @handleEdit="handleEdit"
    >
      <template v-slot:topActions="{ permissions, pageLoader, updateTable }">
        <div
          class="me-md-2 me-sm-1 mb-1 me-0 pa-0 px-3 d-flex align-items-center justify-content-center"
          :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
          v-if="sortPlans"
        >
          <v-autocomplete
            v-model="selectedSortPlan"
            class="w-300px font-weight-medium"
            :label="$t('sort_plan')"
            :items="sortPlans"
            item-text="name"
            item-value="id"
            dense
            outlined
            clearable
            hide-details
            @change="(event) => handleChangeSortPlan(event)"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item v-on="on" :attrs="attrs">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="item.is_active">
                  <span class="badge badge-light-success">{{
                    $t("enabled")
                  }}</span>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>

        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('status')"
        >
          <button
            @click.prevent="() => handleEnable(selectedSortPlan)"
            class="btn btn--export-filter font-weight-bolder"
          >
            <span class="svg-icon">
              <v-icon size="18">mdi-restart</v-icon>
            </span>

            <template>
              <span>{{ $t("enable") }}</span>
            </template>
          </button>
        </div>

        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('save_sort_plan')"
        >
          <button
            @click.prevent="showAddSortPlan"
            class="btn btn--export-filter font-weight-bolder"
          >
            <span class="svg-icon">
              <v-icon size="18">mdi-plus-box</v-icon>
            </span>

            <template>
              <span>{{ $t("add_sort_plan") }}</span>
            </template>
          </button>
          <SaveSortPlan
            :pageLoader="pageLoader"
            :refresher="updateTable"
            :updateSortPlanList="initTableContent"
            ref="save_sort_plan"
          />
        </div>
      </template>
      <template
        v-slot:topRightActions="{
          permissions,
          pageLoader,
          selectedItems,
          updateTable,
        }"
      >
        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('change_sort_type')"
        >
          <button
            class="btn btn--export-filter font-weight-bolder"
            @click.prevent="changeSortTypeAction"
          >
            Change Sort Type
          </button>
        </div>
      </template>
    </DataTable>
    <ChangeSortTypeModal
      ref="changeSortType"
      :refresher="updateTableContent"
    ></ChangeSortTypeModal>

    <EditItem
      ref="editModal"
      :pageLoader="pageLoad"
      :refresher="updateTableContent"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable";
import EditItem from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/EditItem";
import SaveSortPlan from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/SaveSortPlan";
import ChangeSortTypeModal from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/ChangeSortTypeModal";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_SELECTED_SORT_PLAN,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/wesConfigurationChutes.module";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

export default {
  name: "WESConfigurationChutes",
  components: { EditItem, DataTable, SaveSortPlan, ChangeSortTypeModal },
  data: () => ({
    selectedSortPlan: null,
    // componentForEditing: EditItem,
    // componentForEditing: ActionsDialog,
    delete_url: `${process.env.VUE_APP_BASE_URL}/wes/wesChutes/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getWESCONFIGCHUTESTableFilters",
      getTableState: "getWESCONFIGCHUTESTableState",
      getTableData: "getWESCONFIGCHUTESTableData",
      getTableHeaders: "getWESCONFIGCHUTESTableHeaders",
      getTableProperties: "getWESCONFIGCHUTESTableProperties",
      getTableExportUrl: "getWESCONFIGCHUTESExportUrl",
      getTablePermissions: "getWESCONFIGCHUTESTablePermissions",
      getUploadType: "getWESCONFIGCHUTESSUploadType",
    },
  }),
  methods: {
    changeSortTypeAction() {
      this.$refs.changeSortType.toggleModal();
    },
    async initTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let state = {
      //   ...this.$store.getters.getWESCONFIGCHUTESTableState,
      //   // ...(this.isWarehouseRequired && {
      //   //   warehouse_id: this.$store.getters.getSelectedWarehouse,
      //   // }),
      // };
      // state.page = 1;
      // await this.$store
      //   .dispatch(this.table_state.UPDATE_TABLE_DATA, {
      //     ...this.$store.getters.getWESCONFIGCHUTESTableState,
      //     page: 1,
      //   })
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});

      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, {})
        .then(() => {
          this.selectedSortPlan = this.sortPlans.find(
            (item) => item.is_active
          ).id;

          this.$store.commit(SET_SELECTED_SORT_PLAN, this.selectedSortPlan);
          if (this.selectedSortPlan) {
            this.addSortPlanToFilters(this.selectedSortPlan);
            // this.$store.commit(SET_FILTERS_DATA, {
            //   sort_plan: this.selectedSortPlan,
            // });
            //
            // this.$store.commit(SET_TABLE_FILTER, [
            //   {
            //     name: "sort_plan",
            //     value: this.selectedSortPlan,
            //   },
            // ]);
          }
        });
    },
    handleEnable(selectedSortPlan) {
      if (selectedSortPlan) {
        SwalService.warningConditionMessage(
          {
            html: this.$t("are_you_sure_to_enable_selected_chute_mapping"),
          },
          () => {
            this.$store.commit(SET_PAGE_LOADING, true);
            ApiService.post("/wes/wesChutes/status", {
              sort_plan_id: selectedSortPlan,
            })
              .then(async () => {
                SwalService.successMessage({
                  title: SwalService.titles.enabled,
                });
                await this.initTableContent();
                // await this.$store.dispatch(
                //   this.table_state.UPDATE_TABLE_DATA,
                //   {}
                // );
              })
              .catch(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
          }
        );
      } else {
        SwalService.warningMessage({
          title: this.$t("please_select_sort_plan"),
        });
      }
    },
    showAddSortPlan() {
      this.$refs.save_sort_plan.toggleModal();
    },
    addSortPlanToFilters(sort_plan) {
      const filterData = { ...this.$store.getters.getFiltersData };
      filterData.sort_plan = sort_plan;

      this.$store.commit(SET_FILTERS_DATA, {
        ...filterData,
      });

      const tableStateFilters = [
        ...this.$store.getters.getWESCONFIGCHUTESTableState.filters,
      ];

      const foundIndex = tableStateFilters.findIndex(
        (item) => item.name === "sort_plan"
      );
      if (foundIndex > -1) tableStateFilters[foundIndex].value = sort_plan;
      else tableStateFilters.push({ name: "sort_plan", value: sort_plan });
      this.$store.commit(SET_TABLE_FILTER, tableStateFilters);
    },
    handleChangeSortPlan(sort_plan) {
      if (sort_plan) {
        // console.log("sort_plan", sort_plan);
        this.$store.commit(SET_PAGE_LOADING, true);
        this.addSortPlanToFilters(sort_plan);
        this.$refs.datatable.submitFilterValues();
      }

      this.$store.commit(SET_SELECTED_SORT_PLAN, sort_plan);
    },
    handleEdit(item) {
      // console.log("sdasd", item);
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.editModal.toggleModal();
    },
    async updateTableContent() {
      // const tableStateFilters = [
      //   ...this.$store.getters.getWESCONFIGCHUTESTableState.filters,
      // ];
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        ...this.$store.getters.getWESCONFIGCHUTESTableState,
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
  },
  computed: {
    actions: function () {
      return [
        {
          name: "edit",
          title: this.$t("edit"),
          type: "normal",
          method: "handleEdit",
          isVisible: true,
        },
      ];
    },
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    sortPlans: function () {
      return this.$store.getters.getWESCONFIGCHUTESSortPlans;
    },
  },
  beforeMount() {
    this.initTableContent();
  },
};
</script>
